
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
*{
    padding: 0 !important;
    margin: 0 !important;
}
body{
  direction: rtl;
  font-family: "Tajawal", sans-serif !important;
}
.d-center{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.d-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.input-custom {
    background-color: #f5f5f5;
    border: 0px solid #fff;
    border-radius: 30px;
    height: 45px;
    padding: 5px 15px;
    border-right: 3px solid #ef7067 !important;
    outline: none;
    width: 100%;
}
.mx-6 {
    margin-right: 5rem !important;
    margin-left: 7rem !important;
}
.boxsado{
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)
}
.vh-75{
    height: 93vh;
}
.border-red{
    border: 2px solid black  !important;
    font-size: 20px !important;
    cursor: pointer;
}
.checkbox-red .form-check-input:checked {
    color: red;
}
.form-check-input:checked {
    background-color: black !important;
    border-color: black !important;
}
.text-color{
    color: #1ebcd9!important;
}
.submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .submenu.open {
    max-height: 336px;
}
  .linknone{
    color: black;
    list-style: none !important;
    text-decoration: none;
  }
  .sidebar-menu-item{
    list-style: none !important;
  }
  .hoverstyle{
    padding: 10px !important;
    text-align: center;
    transition: 0.5s;
    cursor: pointer;
    font-weight: bold;
    

  }
  .hoverstyle:hover .linknone{
    color: white!important;
    text-decoration: none;
  }
  .hoverstyle:hover .fa-home{
    color: white !important;
    
  }
  .hoverstyle:hover{
    background-color: #1ebcd9;
    color: white !important;
    padding: 10px !important;
    text-align: center;
    border-radius: 5px;
  }
.title{
    color: #678098;
}
.hoverli{
    padding: 6px !important;
}
.hoverli:hover{
background-color: #1ebcd947;
cursor: pointer;
}
.boxshado{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.pointer{
    cursor: pointer;
}
.h-60{
  height: 60px;
}
.bg-pell{
  background-color: #1ebcd9;
  color: white;
  padding: 4px !important;
  border-radius: 30%;
  font-family: "Open Sans", sans-serif;
font-size: 13px;
}
.imgnavbar{
  margin-right: 5px;
  margin-top: -8px;
  height: 40px;
  width: 40px;
  display: inline-block;
}
.bg-color{
  background-color: #1ebcd9 !important;
}
/* .border-w{
  border: 2px solid #1ebcd9 !important;
  
} */
.vh-50{
  height: 60vh;
}
.pointer{
  cursor: pointer;
}
.h-10{
  height: 7%;
}
.table-icon{
  border: 1px solid #8E44AD !important;
}
.bg-editor{
  background-color: #8E44AD !important;
}
.font-15{
  font-size: 15px;
}
.btn-primary{
  background-color: #1ebcd9!important;
  border: none !important;
  padding: 3px !important;
}
/* .font-24{
  height: 20px !important;
} */
.label-success{
  background-color: #ed6b75;
}
.border-alert{
  border: 1px solid #ed6b75 !important;
}
.bg-redmeut{
  background-color: #ed6b75 !important;
  border-radius: 6px;
}
.border-text{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ed6b75;
}
.table-row {
  margin-top: 20px; /* Adjust the top margin as needed */
  margin-bottom: 20px; /* Adjust the bottom margin as needed */
}
td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

}
th {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

}
.bg-th{
  background-color: #eceef1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.font-22{
  font-size: 22px !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: white  !important;
  background-color:#1ebcd9 !important;
}
.nav-link{
  color: black !important;
  font-weight: bold !important;
}
.modal-title{
  width: 140% !important;
}
.modal-dialog{
  margin-right: auto !important;
  margin-left: auto !important;
}
.w-111{
  width: 200% !important;
}
.bg-gray{
  background-color: #215d9330 !important;
  height: 40px;
}
.foterbo{
  margin-top: 120px !important;
  position: absolute;
  bottom: 0 !important;
  /* top: 0 !important; */
}
.w-40{
  width: 40%;
}
.w-60{
  width: 60%;
}
.btn-close{
  color: white !important;
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.modal-dialog{
  margin-top: 40px !important;
}
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 619px !important;
  width: 109% !important;
  left: 117px !important;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 171% !important;
}
.react-datepicker-wrapper{
  width: 100%;
}
.d-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.rdt_Pagination{
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 20px !important;
}
.font-20{
  font-size: 20px;
}
.border-50{
  border-radius: 15px;
}